(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var SearchWord = (function(){
  function SearchWord(){
    this.error_flg = 0;
    if($(".keyword-attach").length == 0){
      this.error_flg = 1;
      return false;
    };

    $(".keyword-attach").each(function(){
      var bodyHTML = $(this).html();
      $.each(searchWordKeywords, function(k,v){
        var replacedHTML = "<span class='searchWordKeyword' data-wordid='"+k+"'>"+v['keyword']+"</span>";
        bodyHTML = bodyHTML.replace(new RegExp(v["keyword"], 'g'), replacedHTML);
      });
      $(this).html(bodyHTML);
    });

  }
  SearchWord.prototype.popup = function(){
    if(this.error_flg == 1) return false;
    var Timer = {};
    $("body").on("mouseenter", ".searchWordKeyword", function(){
      var self = this;
      var target = $(this).data("wordid");
      clearTimeout(Timer[target]);
      if($(".searchWordPopup--"+target).length > 0) return false;
      var offset = $(self).offset();
      var bodyHeight = $("body").height();
      var bottom = bodyHeight - offset.top + 8;
      var left = offset.left;
      var popupHTML = "<div class='searchWordPopup searchWordPopup--"+target+"' data-wordid='"+target+"' style='left: "+left+"px; bottom: "+bottom+"px'>"+searchWordKeywords[target]['detail']+"</div>"
      $(popupHTML).appendTo("body");
      Timer[target] = setTimeout(function(){
        $(".searchWordPopup--"+target).addClass("is-show");
      }, 100);
    });
    $("body").on("mouseenter", ".searchWordPopup", function(){
      var target = $(this).data("wordid");
      clearTimeout(Timer[target]);
    });
    $("body").on("mouseleave", ".searchWordKeyword, .searchWordPopup", function(){
      var target = $(this).data("wordid");
      Timer[target] = setTimeout(function(){
        $(".searchWordPopup--"+target).removeClass("is-show").transitionEnd(function(){
          $(this).remove();
        });
      }, 300);
    });
  }

  return SearchWord;

})();

$(function(){
  var searchWord = new SearchWord();
  searchWord.popup();
});
}).call(this,require("pBGvAp"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/searchWords.js","/")